import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import moment from "moment";
import BillingAddInputField from "../BillingAddInputField";
import Images from "../../assets/images/Images";
import PopupModal from "../PopupModal";

const NewBill = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      date: moment().toDate(),
      others: 0,
      currentAmount: 0,
      currentlypaidAmount: 0,
      paymentType: "Full Payment",
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    addData: false,
  });
  const { state } = useLocation();
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  const [selectedHostel, setSelectedHostel] = useState("");

  const getSelectedHostelFromState = (state) => {
    if (!state) return branchNameArray[0] || ""; // Default to the first branch or empty
    if (typeof state === "string") return state; // Handle string state
    if (typeof state === "object" && state?.selectHostel)
      return state?.selectHostel; // Handle object state
    return branchNameArray[0] || ""; // Fallback
  };

  useEffect(() => {
    const initialHostel = getSelectedHostelFromState(state);
    setSelectedHostel(initialHostel);
    setValue("selectHostel", initialHostel);
  }, [state, setValue]);

  const [details, setDetails] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const getViewRegister = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newregister/getAllNewRegister/${selectedHostel}`
    );
  };
  const getBillNo = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newbills/${selectedHostel}/lastBillNo`
    );
  };

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };
  const handleNavigate = () => {
    navigate("/viewBill", { state: selectedHostel });
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/newbills/${selectedHostel}`,
        postData
      ),
    {
      onSuccess: () => {
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  const { data } = useQuery(
    ["getViewRegister", selectedHostel],
    getViewRegister,
    {
      enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
    }
  );
  const registerDetails = data && data?.data;

  const { data: lastbill } = useQuery(
    ["getBillNo", selectedHostel],
    getBillNo,
    {
      enabled: !!selectedHostel,
    }
  );

  let billgenerate = lastbill && lastbill?.data;

  const {
    currentAmount,
    rentForMonth,
    others,
    powerUsedFrom,
    powerUsedTo,
    paymentType,
    amountPerUnit,
    currentlypaidAmount,
    total,
  } = watch();

  const currentamt = Number(powerUsedTo) - Number(powerUsedFrom);
  const currentamounts = Number(amountPerUnit) * currentamt;

  const totalAmount = `${
    Number(currentAmount) + Number(rentForMonth) + Number(others)
  }`;
  const remainingbalance = `${
    Number(totalAmount) - Number(currentlypaidAmount)
  }`;

  useEffect(() => {
    if (totalAmount && totalAmount) {
      setValue("billNo", Number(billgenerate));
      setValue("total", Number(totalAmount));
    }
    if (currentamounts) {
      setValue("currentAmount", currentamounts);
    }
    if (paymentType === "Full Payment") {
      setValue("currentlypaidAmount", Number(total));
    } else if (paymentType === "Part Payment") {
      if (currentlypaidAmount === totalAmount) {
        setValue("currentlypaidAmount", 0); // Reset to 0 for part payment
      }
    }
    if (remainingbalance) {
      setValue("remainingAmount", Number(remainingbalance));
    }
  }, [
    setValue,
    paymentType,
    totalAmount,
    remainingbalance,
    currentlypaidAmount,
    currentamounts,
    total,
  ]);

  const users = registerDetails?.filter(
    (item) => Number(item?.mobileNo) === Number(searchTerm)
  );
  const usersdetails = users?.[0] || {};
  useEffect(() => {
    if (searchTerm && searchTerm) {
      setValue("name", usersdetails?.name || "");
      setValue("roomNo", usersdetails?.roomNo || "");
      setValue("mobileNumber", usersdetails?.mobileNo || "");
      setValue("email", usersdetails?.emailId || "");
      setValue("rentForMonth", usersdetails?.rentForMonth || "");
    }
  }, [searchTerm, usersdetails, setValue]);
  useEffect(() => {
    setValue("name", state?.name || "");
    setValue("roomNo", state?.roomNo || "");
    setValue("mobileNumber", state?.mobileNo || "");
    setValue("email", state?.emailId || "");
    setValue("rentForMonth", state?.rentForMonth);
  }, [state, setValue]);

  const onSubmit = (data) => {
    let formData = {
      name: data?.name,
      date: moment(data?.date).format("YYYY-MM-DD"),
      // moment(data.date).format('DD/MM/YYYY');
      mobileNumber: data?.mobileNumber,
      emailId: data?.email,
      roomNo: data?.roomNo,
      currentAmount: data?.currentAmount,
      others: data?.others,
      rentForMonth: data?.rentForMonth,
      role: data?.selectHostel,
      total: data?.total,
      billNo: data?.billNo,
      powerUsedTo: data?.powerUsedTo,
      powerUsedFrom: data?.powerUsedFrom,
      currentlypaidAmount: data?.currentlypaidAmount,
      remainingAmount: data?.remainingAmount,
      payementType: data?.paymentType,
      selecthostel: data?.amountPerUnit,
    };
    setDetails(formData);
    if (formData && selectedHostel) {
      mutate(formData);
    } 
  };

  return (
    <>
      <div className="row py-2 px-2 m-0 new-register-heading-block">
        <div className="col-12 col-sm-4 col-md-4 my-2 px-1">
          <div className="new-register-title-block">
            <img
              // src="/assets/images/arrow-blue.png"
              src={Images?.arrowBack}
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">NEW BILL</h5>
          </div>
        </div>
        <div className="col-12 col-sm-8 d-flex justify-content-between justify-content-md-end align-items-center gap-2 my-2 px-1">
          <input
            type="search"
            className="view-bill-search"
            placeholder="Mobile Number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Controller
            name="selectHostel"
            control={control}
            defaultValue={selectedHostel}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange } }) => (
              <select
                value={selectedHostel}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSelectedHostel(newValue); // Update local state
                  onChange(newValue); // Update react-hook-form field
                }}
                className={`selectDropDown ${
                  errors.selectHostel ? "error" : ""
                }`}
              >
                <option value="">Branch Name</option>
                {branchNameArray.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
          <button
            className="view-register-btn"
            onClick={() => {
              navigate("/viewBill");
            }}
          >
            VIEW BILL
          </button>
        </div>
      </div>

      <div className="register-form-main-block">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="rentalFormBlock my-3"
        >
          <div className=" py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Date <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, onBlur, value } }) => (
                  <DatePicker
                    className="bookingDateEditInput"
                    onChange={onChange} // Call onChange to update the form state
                    onBlur={onBlur} // Call onBlur to trigger validation
                    selected={value} // Set the selected date
                    placeholderText="DD/MM/YY"
                    minDate={new Date()} // Prevent selecting past dates
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Bill No</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="billNo"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Bill Number"
                    type="number"
                    bookingEditInput="bookingEditInput"
                  />
                )}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Name
                <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="name"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Customer Name"
                    type="text"
                    bookingEditInput="bookingEditInput"
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Mobile Number <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="mobileNumber"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Mobile Number"
                    type="number"
                    bookingEditInput="bookingEditInput"
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Email ID</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="email"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Email Id"
                    type="emailid"
                    bookingEditInput="bookingEditInput"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Room No</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="roomNo"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Room No"
                    bookingEditInput="bookingEditInput"
                    type="text"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Power Used Units <span style={{ color: "red" }}>*</span>
              </label>
            </div>

            <div className="billingInputFieldBlock d-flex">
              <div className="fromUnitsBlock">
                <Controller
                  control={control}
                  name="powerUsedFrom"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="from Units"
                      bookingEditInput="bookingEditInput"
                      type="number"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className="toUnitsBlock">
                <Controller
                  control={control}
                  name="powerUsedTo"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="to Units"
                      bookingEditInput="bookingEditInput"
                      type="number"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock d-flex justify-content-end">
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="amountPerUnit"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Amount Per Unit"
                    bookingEditInput="bookingEditInput"
                    type="text"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">EB Amount</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="currentAmount"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="currentAmount"
                    bookingEditInput="bookingEditInput"
                    type="number"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>

          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Rent For Month <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="rentForMonth"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Rent For Month"
                    bookingEditInput="bookingEditInput"
                    type="number"
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          {/* OLD BALANCE START */}
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Others</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="others"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Others"
                    bookingEditInput="bookingEditInput"
                    type="number"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>

          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Total Amount</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="total"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Total"
                    bookingEditInput="bookingEditInput"
                    type="number"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Payment Method</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                name="paymentType"
                control={control}
                defaultValue="Full Payment"
                render={({ field }) => (
                  <select {...field} className="bookingEditInput">
                    <option value="">PAYMENT METHOD</option>
                    <option value="Full Payment">Full Payment</option>
                    <option value="Part Payment">Part Payment</option>
                  </select>
                )}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Currently Paid Amount
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="currentlypaidAmount"
                render={({ field }) => (
                  <input
                    {...field}
                    value={field.value || 0}
                    placeholder={
                      paymentType === "Full Payment"
                        ? "Total Amount"
                        : "Enter Amount"
                    }
                    className="bookingEditInput"
                    type="number"
                  />
                )}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Pending Amount</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="remainingAmount"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Pending"
                    bookingEditInput="bookingEditInput"
                    type="number"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-4 px-4  billingSubmitButton">
            <button type="submit" className="formSubmit">
              {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "Submit"}
            </button>
          </div>
        </form>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        message="Bill Added Successfully.."
        selecthostel={selectedHostel}
        onDone={handleNavigate}
      />
    </>
  );
};

export default NewBill;
