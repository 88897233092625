import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import moment from "moment/moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
// import downloadIcon from "../../public/assets/images/download.png"
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import Images from "../../assets/images/Images";
import PopupModal from "../PopupModal";
import ImageModal from "../popup/ImageModal";

const ViewRegister = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const queryClient = useQueryClient();
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);
  const [deletePopup, setDeletePopUp] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const itemsPerPage = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [totalcount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [InactiveCount, setInactiveCount] = useState(0);
  const storedBranchNames = localStorage.getItem("branchNames");
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);

  const handleImageClick = (imageUrl) => {
    setCurrentImage(imageUrl);
    setModalOpen(true);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    removeConfirm: false,
    deleteId: null,
    removeSucess: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      removeConfirm: false,
      removeSucess: false,
      deleteId: null,
    });
  };
  const closeImageModal = () => {
    setModalOpen(false);
    setCurrentImage("");
  };

  const { state } = useLocation();
  const branchNameArray = JSON.parse(storedBranchNames) || [];
  const getSelectedHostelFromState = (state) => {
    if (!state) return branchNameArray[0] || ""; // Default to the first branch or empty
    if (typeof state === "string") return state; // Handle string state
    if (typeof state === "object" && state?.selectHostel)
      return state?.selectHostel; // Handle object state
    return branchNameArray[0] || ""; // Fallback
  };

  const [selectedHostel, setSelectedHostel] = useState("");

  useEffect(() => {
    const initialHostel = getSelectedHostelFromState(state);
    setSelectedHostel(initialHostel);
    setValue("selectHostel", initialHostel);
  }, [state, setValue]);

  const getViewRegister = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newregister/getAllNewRegister/${selectedHostel}`
    );
  };
  const { data, isLoading, isError, refetch } = useQuery(
    ["getViewRegister", selectedHostel],
    getViewRegister,
    {
      enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
    }
  );

  let details = data && data?.data;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const applyFilterAndSearch = () => {
      if (!details) {
        setFilteredData([]);
        return;
      }

      // Normalize searchTerm (convert to lowercase and remove spaces)
      const normalizedSearchTerm = (searchTerm?.toLowerCase() ?? "").replace(
        /\s+/g,
        ""
      );

      const result =
        details &&
        details?.filter((item) => {
          // Ensure that the filter criteria is applied
          const matchesFilter = filter ? item?.status === filter : true;

          // Handle dateRange with destructuring (ensure both startDate and endDate are defined)
          const [startDate, endDate] = dateRange || [null, null];

          // Normalize item fields for comparison
          const itemRoomNo = (item?.roomNo ?? "")
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "");
          const itemName = (item?.name ?? "")
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "");

          // Check if any of the normalized fields match the normalized searchTerm
          const matchesSearch =
            !normalizedSearchTerm ||
            itemRoomNo?.startsWith(normalizedSearchTerm) ||
            itemName?.startsWith(normalizedSearchTerm);

          // Normalize item dates (if they exist)
          const itemJoinDate = item?.dateOfJoining
            ? moment(item?.dateOfJoining)
            : null;
          const itemLeaveDate = item?.dateOfLeaving
            ? moment(item?.dateOfLeaving)
            : null;

          // Check if the item dateOfJoining or dateOfLeaving is within the selected date range (inclusive)
          const matchesDateRange =
            (!startDate ||
              (itemJoinDate &&
                itemJoinDate.isSameOrAfter(moment(startDate)) &&
                itemJoinDate.isSameOrBefore(moment(endDate))) ||
              (itemLeaveDate &&
                itemLeaveDate.isSameOrAfter(moment(startDate)) &&
                itemLeaveDate.isSameOrBefore(moment(endDate)))) ??
            true; // Default to true if no date range is provided

          // Return true if the item matches all criteria
          return matchesFilter && matchesSearch && matchesDateRange;
        });

      setFilteredData(result); // Set the filtered data based on the criteria
    };

    applyFilterAndSearch(); // Apply the filter and search when any dependency changes
  }, [details, filter, searchTerm, dateRange]); // Dependencies that trigger the effect

  // Pagination logic
  const totalPages = Math.ceil(
    filteredData && filteredData?.length / itemsPerPage
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handlers
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages || totalPages === 0;

  const handlePrevPage = () => {
    if (!isPrevDisabled) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (!isNextDisabled) setCurrentPage(currentPage + 1);
  };
  const deleteItem = async (id) => {
    await axios.delete(
      `${process.env.REACT_APP_HOST}/newregister/${selectedHostel}/${id}`
    );
  };

  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      refetch();
      setPopupState({
        removeConfirm: false,
        removeSucess: true,
      });
    },
    onError: (err) => {
      console.log("Error", err);
    },
  });

  const handleDelete = (id) => {
    mutation.mutate(id);
  };

  // Showing Dashboard Details
  useEffect(() => {
    // Initialize totals
    let totalreg = 0;
    let activeCount = 0;
    let inactiveCount = 0;

    // Compute totals
    filteredData?.forEach((item) => {
      totalreg += 1;
      if (item?.status == "Active") {
        activeCount += 1;
      } else if (item?.status == "Inactive") {
        inactiveCount += 1;
      }
    });

    // Update state with computed totals
    setTotalCount(totalreg);
    setActiveCount(activeCount);
    setInactiveCount(inactiveCount);
  }, [currentItems]);

  const downloadExcel = () => {
    // Prepare empty data if there's no valid data to export
    const exportData =
      filteredData && filteredData.length > 0 ? filteredData : data;

    const tableDatas =
      exportData && exportData?.length > 0
        ? exportData?.map((data, index) => ({
            "S.NO": index + 1,
            NAME: data?.name || "NULL",
            "ROOM NO": data?.roomNo || "NULL",
            "MOBILE NUMBER": data?.mobileNo || "NULL",
            "PROFESSION TYPE": data?.proffesionType || "NULL",
            // "AADHAR NUMBER": data?.aadharNumber || "NULL",
            "EMAIL ID": data?.emailId || "NULL",
            "DATE OF BIRTH": data?.dateOfBirth || "NULL",
            "DATE OF JOINING": data?.dateOfJoining || "NULL",
            "PARENT/GUARDIAN": data?.parentialOrGuardian || "NULL",
            "GUARDIAN NUMBER": data?.familyNumber || "NULL",
            "FOOD OPTION": data?.selectFoodOption || "NULL",
            "ADVANCE PAYMENT": data?.advanceAmount || "NULL",
            "RENT FOR MONTH": data?.rentForMonth || "NULL",
            ADDRESS: data?.address || "NULL",
            STATUS: data?.status || "NULL",
            "DATE OF LEAVING": data?.dateOfLeaving || "NULL",
          }))
        : []; // Default to an empty array

    // Create a new workbook and add an empty sheet if there's no data
    const workbook = XLSX.utils.book_new();
    const tableWorksheet = XLSX.utils.json_to_sheet(
      tableDatas?.length > 0 ? tableDatas : [{}],
      {
        skipHeader: false,
      }
    );

    // Optionally set headers even if no data
    if (tableDatas?.length === 0) {
      XLSX.utils.sheet_add_aoa(tableWorksheet, [
        [
          "S.NO",
          "NAME",
          "ROOM NO",
          "MOBILE NUMBER",
          "PROFESSION TYPE",
          // "AADHAR NUMBER",
          "EMAIL ID",
          "DATE OF BIRTH",
          "DATE OF JOINING",
          "PARENT/GUARDIAN",
          "GUARDIAN NUMBER",
          "FOOD OPTION",
          "ADVANCE PAYMENT",
          "RENT FOR MONTH",
          "ADDRESS",
          "STATUS",
          "DATE OF LEAVING",
        ],
      ]);
    }

    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "View Register.xlsx");
  };

  return (
    <>
      <div className="row py-2 px-2 m-0 new-register-heading-block">
        <div className="col-12 col-md-6 d-flex  justify-content-between align-items-center my-2 px-1">
          <div className="new-register-title-block">
            <img
              src={Images?.arrowBack}
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">VIEW REGISTER</h5>
          </div>
          {/* <div className="daterange-viewbill p-0 m-0">
            <Controller
              name="dateRange"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div style={{ width: "100%" }}>
                  <DatePicker
                    className="datefilter"
                    selected={value && value.length ? value[0] : null} // Start date
                    startDate={value && value.length ? value[0] : null}
                    endDate={value && value.length ? value[1] : null} // End date
                    onChange={(update) => {
                      onChange(update); // Update both start and end dates
                      setDateRange(update); // Set the date range in local state for filtering
                    }}
                    selectsRange
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Filter By Dates"
                    isClearable
                  />
                </div>
              )}
            />
          </div> */}
          <Controller
            name="selectHostel"
            control={control}
            defaultValue={selectedHostel}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange } }) => (
              <select
                value={selectedHostel}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSelectedHostel(newValue); // Update local state
                  onChange(newValue); // Update react-hook-form field
                }}
                className={`selectDropDown ${
                  errors.selectHostel ? "error" : ""
                }`}
              >
                <option value="">Branch Name</option>
                {branchNameArray.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
        </div>
        <div className="col-12 col-md-6  d-flex justify-content-between  align-items-center gap-2 my-2 px-1">
          <div
            className="overview-download-block"
            onClick={() => downloadExcel()}
          >
            <div className="search-icon d-flex justify-content-center align-items-center">
              <img
                src={Images?.download}
                alt="no-image"
                className="crm-image"
              />
            </div>
            <button className="overview-btn">DOWNLOAD</button>
          </div>

          <select
            className="view-bill-status"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
          >
            <option value="">Filter</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          <input
            type="search"
            className="view-bill-search"
            placeholder="search by roomno , name "
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="status-bar-block">
        <div className="status-row-bar">
          <div className="bill-status-bar --first-bar">
            <p className="bill-status-content">ACTIVE</p>
            <p className="bill-status-count">{activeCount} Person</p>
          </div>
          <div className="bill-status-bar">
            <p className="bill-status-content">INACTIVE</p>
            <p className="bill-status-count">{InactiveCount} Person</p>
          </div>
          <div className="bill-status-bar --last-bar">
            <p className="bill-status-content">TOTAL</p>
            <p className="bill-status-count">{totalcount} Person</p>
          </div>{" "}
        </div>
      </div>
      <div className="view-register-table-block">
        <div className="view-bill-main-block">
          <div className="table-responsive table-container">
            <table className="table">
              <thead>
                <tr>
                  <th className="view-bill-head --first-head">NAME</th>
                  <th className="view-bill-head">ROOM NO</th>
                  <th className="view-bill-head">MOBILE NO</th>
                  <th className="view-bill-head">PROFESSION TYPE</th>
                  <th className="view-bill-head">STUDENT PHOTO</th>
                  <th className="view-bill-head">AADHAR PHOTO</th>
                  <th className="view-bill-head">EMPLOYEE ID</th>
                  <th className="view-bill-head">EMAILID</th>
                  <th className="view-bill-head">DATE OF BIRTH</th>
                  <th className="view-bill-head">DATE OF JOINING</th>
                  <th className="view-bill-head">PARENT/GUARDIAN</th>
                  <th className="view-bill-head">GUARDIAN NUMBER</th>
                  <th className="view-bill-head">FOOD OPTION</th>
                  <th className="view-bill-head">ADVANCE PAYMENT</th>
                  <th className="view-bill-head">RENT FOR MONTH</th>
                  <th className="view-bill-head">ADDRESS</th>
                  <th className="view-bill-head">STATUS</th>
                  <th className="view-bill-head">DATE OF LEAVING</th>
                  <th className="view-bill-head --last-head">EDIT</th>
                </tr>
              </thead>
              <tbody>
                {currentItems && currentItems?.length > 0 ? (
                  currentItems?.map((item, index) => (
                    <tr key={index}>
                      <td className="view-bill-data">{item && item?.name}</td>
                      <td className="view-bill-data">{item && item?.roomNo}</td>
                      <td className="view-bill-data">
                        {item && item?.mobileNo}
                      </td>
                      <td className="view-bill-data">
                        {item && item?.proffesionType}
                      </td>
                      <td className="view-bill-data">
                        <img
                          src={
                            item?.uploadPhoto?.startsWith(
                              "data:image/jpeg;base64,"
                            )
                              ? item?.uploadPhoto
                              : `data:image/jpeg;base64,${item?.uploadPhoto}`
                          }
                          alt="no-image"
                          className="search-icon"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleImageClick(item?.uploadPhoto)}
                        />
                      </td>
                      <td className="view-bill-data">
                        <img
                          // src={`data:image/jpeg;base64,${item?.uploadAadhar}`}
                          src={
                            item?.uploadAadhar?.startsWith(
                              "data:image/jpeg;base64,"
                            )
                              ? item?.uploadAadhar
                              : `data:image/jpeg;base64,${item?.uploadAadhar}`
                          }
                          alt="no-image"
                          style={{ cursor: "pointer" }}
                          className="search-icon"
                          onClick={() => handleImageClick(item?.uploadAadhar)}
                        />
                      </td>
                      <td className="view-bill-data">
                        <img
                          // src={`data:image/jpeg;base64,${item?.uploadEmployeeIdCard}`}
                          src={
                            item?.uploadEmployeeIdCard?.startsWith(
                              "data:image/jpeg;base64,"
                            )
                              ? item?.uploadEmployeeIdCard
                              : `data:image/jpeg;base64,${item?.uploadEmployeeIdCard}`
                          }
                          alt="no-image"
                          className="search-icon"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleImageClick(item?.uploadEmployeeIdCard)
                          }
                        />
                      </td>

                      <td className="view-bill-data">{item?.emailId}</td>
                      <td className="view-bill-data">{item?.dateOfBirth}</td>
                      <td className="view-bill-data">{item?.dateOfJoining}</td>
                      <td className="view-bill-data">
                        {item?.parentialOrGuardian}
                      </td>
                      <td className="view-bill-data">{item?.familyNumber}</td>
                      <td className="view-bill-data">
                        {item?.selectFoodOption}
                      </td>
                      <td className="view-bill-data">{item?.advanceAmount}</td>
                      <td className="view-bill-data">{item?.rentForMonth}</td>
                      <td className="view-bill-data">{item?.address}</td>
                      <td className="view-bill-data">{item?.status}</td>
                      <td className="view-bill-data">
                        {item?.status == "Inactive"
                          ? item?.dateOfLeaving
                          : "---"}
                      </td>

                      <td className="view-bill-data">
                        <img
                          src={Images?.editIcon}
                          className="--image-click"
                          alt="no-image"
                          onClick={() =>
                            navigate("/editRegister", { state: item })
                          }
                        />
                        <img
                          src={Images?.deleteIcon}
                          className="--image-click"
                          alt="no-image"
                          onClick={() => {
                            setIsOpen(true);
                            setPopupState({
                              removeConfirm: true,
                              deleteId: item?.id,
                            });
                          }}
                        />
                        <img
                          src={Images?.billIcon}
                          className="--image-click"
                          alt="no-image"
                          onClick={() => {
                            navigate("/newBill", { state: item });
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="19">No results found</td>
                  </tr>
                )}
              </tbody>
              {/* <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                imageUrl={currentImage}
              /> */}
            </table>
          </div>
          <div className="d-flex justify-content-between pt-2 px-1">
            <button
              onClick={handlePrevPage}
              disabled={isPrevDisabled}
              className="arrow-btn"
            >
              &lt;
            </button>
            <button
              onClick={handleNextPage}
              disabled={isNextDisabled}
              className="arrow-btn"
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        setPopupState={setPopupState}
        message="Are you sure you want to delete the user detail?"
        selecthostel={selectedHostel}
        deleteHandler={handleDelete}
        isLoading={isLoading}
        dltmessage="User has been deleted successfully"
      />
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeImageModal}
        imageUrl={currentImage}
      />
    </>
  );
};

export default ViewRegister;
