import axios from "axios";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { Controller, useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the datepicker CSS
import moment from "moment";
import { useLocation } from "react-router-dom";
import Images from "../../assets/images/Images";
import PopupModal from "../PopupModal";
const ViewBill = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({});
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);
  const [deletePopup, setDeletePopUp] = useState(null);
  const itemsPerPage = 5;
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [paid, setPaid] = useState(0);
  const [pendingamt, setPendingamt] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];
  const { state } = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    removeConfirm: false,
    deleteId: null,
    removeSucess: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      removeConfirm: false,
      removeSucess: false,
      deleteId: null,
    });
  };
  // console.log("state", state);
  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    (state && state) || branchNameArray[0] || ""
  );
  const getViewBill = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/newbills/getAllNewBill/${selectedHostel}`
    );
    return response?.data;
  };

  const { data, isLoading, isError, refetch } = useQuery(
    ["getViewBill", selectedHostel],
    getViewBill,
    {
      enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
    }
  );

  const details = data;

  const [filteredData, setFilteredData] = useState([]);

  const logindetails = localStorage.getItem("registers");
  const users = JSON.parse(logindetails);
  const resultData = users?.filter((item, i) => {
    return item?.shopName == watch("selectHostel");
  });

  useEffect(() => {
    const applyFilterAndSearch = () => {
      if (!details) {
        setFilteredData([]);
        return;
      }

      // Normalize searchTerm by converting to lowercase and removing spaces
      const normalizedSearchTerm =
        searchTerm?.toLowerCase().replace(/\s+/g, "") || "";

      const [startDate, endDate] = dateRange;

      const result =
        details &&
        details?.filter((item) => {
          // Ensure that the filter criteria is applied (feesStatus filter)
          const matchesFilter = filter ? item.feesStatus === filter : true;

          // Normalize item fields to lowercase and remove spaces for comparison
          const itemRoomNo = (item?.roomNo ?? "")
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "");
          const itemName = (item?.name ?? "")
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "");
          const itemBillNo = (item?.billNo ?? "")
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "");

          // Check if any of the normalized fields match the normalized searchTerm
          const matchesSearch =
            !searchTerm ||
            itemRoomNo.startsWith(normalizedSearchTerm) ||
            itemName.startsWith(normalizedSearchTerm) ||
            itemBillNo.startsWith(normalizedSearchTerm);

          // Date range filtering logic
          const itemDate = item?.date ? moment(item?.date) : null;

          // Check if the item date is within the selected date range (inclusive of from and to)
          const matchesDateRange =
            !startDate ||
            (itemDate &&
              itemDate.isSameOrAfter(moment(startDate)) &&
              itemDate.isSameOrBefore(moment(endDate)));

          return matchesFilter && matchesSearch && matchesDateRange;
        });

      setFilteredData(result);
    };

    // Run the filter logic only when details, filter, searchTerm, or dateRange changes
    applyFilterAndSearch();
  }, [details, filter, searchTerm, dateRange]); // We include only relevant dependencies

  // Pagination logic
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handlers
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages || totalPages === 0;

  const handlePrevPage = () => {
    if (!isPrevDisabled) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (!isNextDisabled) setCurrentPage(currentPage + 1);
  };

  const deleteItem = async (id) => {
    await axios.delete(
      `${process.env.REACT_APP_HOST}/newbills/${selectedHostel}/${id}`
    );
  };

  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      refetch();
      setPopupState({
        removeConfirm: false,
        removeSucess: true,
      });
    },
    onError: (err) => {
      console.log("Error", err);
    },
  });

  const handleDelete = (id) => {
    mutation.mutate(id);
  };
  useEffect(() => {
    // Initialize totals
    let totalbill = 0;
    let paidCount = 0;
    let pendingCount = 0;

    // Compute totals
    filteredData?.forEach((item) => {
      totalbill += 1;
      if (item?.remainingAmount === 0) {
        paidCount += 1;
      } else if (item?.remainingAmount !== 0) {
        pendingCount += 1;
      }
    });

    // Update state with computed totals
    setCount(totalbill);
    setPaid(paidCount);
    setPendingamt(pendingCount);
  }, [currentItems]);

  // Function to handle Excel download
  const downloadExcel = () => {
    // Determine data to export based on filter
    const exportData =
      filteredData && filteredData.length > 0 ? filteredData : filteredData;

    // Prepare data for the table worksheet
    const tableDatas =
      exportData && exportData?.length > 0
        ? exportData?.map((data, index) => ({
            "S.NO": index + 1,
            NAME: data?.name || "NULL",
            "ROOM NO": data?.roomNo || "NULL",
            "MOBILE NUMBER": data?.mobileNumber || "NULL",
            "EMAIL ID": data?.emailId || "NULL",
            "DATE OF BILL": data?.date || "NULL",
            "BILL NO": data?.billNo || "NULL",
            // "ADVANCE PAYMENT": data?.advanceAmount || "NULL",
            "RENT FOR MONTH": data?.rentForMonth || "NULL",
            "EB AMOUNT": data?.currentAmount || "NULL",
            OTHERS: data?.others || "NULL",
            "TOTAL AMOUNT": data?.total || "NULL",
            "TOTAL PAID": data?.currentlypaidAmount || "NULL",
            "PENDING AMOUNT": data?.remainingAmount || "NULL",
          }))
        : []; // Default to an empty array

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(
      tableDatas?.length > 0 ? tableDatas : [{}],
      {
        skipHeader: false, // Ensure the header is included
      }
    );

    // If tableDatas is empty, add headers manually
    if (tableDatas?.length === 0) {
      XLSX.utils.sheet_add_aoa(tableWorksheet, [
        [
          "S.NO",
          "NAME",
          "ROOM NO",
          "MOBILE NUMBER",
          "EMAIL ID",
          "DATE OF BILL",
          "BILL NO",
          "ADVANCE PAYMENT",
          "RENT FOR MONTH",
          "EB AMOUNT",
          "OTHERS",
          "TOTAL AMOUNT",
          "TOTAL PAID",
          "PENDING AMOUNT",
        ],
      ]);
    }

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "View Bill.xlsx");
  };
  useEffect(() => {
    if (state && state) {
      setSelectedHostel(state);
      setValue("selectHostel", state);
    }
  }, [state, setValue]);
  return (
    <>
      <div className="row  py-2 px-2 m-0 new-register-heading-block">
        <div className="col-12  col-md-6 d-flex  justify-content-between align-items-center  my-2 px-1">
          <div className="new-register-title-block">
            <img
              // src="/assets/images/arrow-blue.png"
              src={Images?.arrowBack}
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="view-bill-title pt-2">VIEW BILL</h5>
          </div>
          {/* <div className="daterange-viewbill p-0 m-0">
            <Controller
              name="dateRange"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div style={{ width: "100%" }}>
                  <DatePicker
                    className="datefilter"
                    selected={value && value.length ? value[0] : null} // Start date
                    startDate={value && value.length ? value[0] : null}
                    endDate={value && value.length ? value[1] : null} // End date
                    onChange={(update) => {
                      onChange(update); // Update both start and end dates
                      setDateRange(update); // Set the date range in local state for filtering
                    }}
                    selectsRange
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Filter By Dates"
                    isClearable
                  />
                </div>
              )}
            />
          </div> */}
          <Controller
            name="selectHostel"
            control={control}
            defaultValue={selectedHostel}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange } }) => (
              <select
                value={selectedHostel}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSelectedHostel(newValue); // Update local state
                  onChange(newValue); // Update react-hook-form field
                }}
                className={`selectDropDown-viewBill ${
                  errors.selectHostel ? "error" : ""
                }`}
              >
                <option value="">Branch Name</option>
                {branchNameArray.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
        </div>
        <div className="col-12    col-md-6  d-flex justify-content-between  align-items-center gap-2 my-2 px-1">
          <div
            className="overview-download-block"
            onClick={() => downloadExcel()}
          >
            <div className="search-icon d-flex justify-content-center align-items-center">
              <img
                // src="/assets/images/download.png"
                src={Images?.download}
                alt="no-image"
                className="crm-image"
              />
            </div>
            <button className="overview-btn">DOWNLOAD</button>
          </div>

          <select
            className="view-bill-status"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
          >
            <option value="">Filter</option>
            <option value="Paid">Paid</option>
            <option value="Pending">Pending</option>
          </select>
          <input
            type="search"
            className="view-bill-search"
            placeholder="search by roomno , name , billno"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="status-bar-block">
        <div className="status-row-bar">
          <div className="bill-status-bar --first-bar">
            <p className="bill-status-content">PAID</p>
            <p className="bill-status-count">{paid} Person</p>
          </div>
          <div className="bill-status-bar">
            <p className="bill-status-content">TOTAL</p>
            <p className="bill-status-count">{count} Person</p>
          </div>
          <div className="bill-status-bar --last-bar">
            <p className="bill-status-content">PENDING</p>
            <p className="bill-status-count">{pendingamt} Person</p>
          </div>{" "}
        </div>
      </div>

      <div className="view-register-table-block">
        <div className="view-bill-main-block">
          <div className="table-responsive table-container">
            <table className="table">
              <thead>
                <tr>
                  <th className="view-bill-head --first-head">NAME</th>
                  <th className="view-bill-head">DOWNLOAD</th>
                  <th className="view-bill-head">ROOM NO</th>
                  <th className="view-bill-head">BILL NO</th>
                  <th className="view-bill-head">MOBILE NUMBER</th>
                  <th className="view-bill-head">EMAILID</th>
                  <th className="view-bill-head">DATE</th>
                  <th className="view-bill-head">RENT FOR MONTH</th>
                  <th className="view-bill-head">EB AMOUNT</th>
                  <th className="view-bill-head">OTHERS</th>
                  <th className="view-bill-head">TOTAL AMOUNT</th>
                  <th className="view-bill-head">AMOUNT PAID</th>
                  <th className="view-bill-head">PENDING AMOUNT</th>
                  <th className="view-bill-head --last-head">EDIT</th>
                </tr>
              </thead>
              <tbody>
                {currentItems && currentItems?.length > 0 ? (
                  currentItems?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td className="view-bill-data">{item?.name}</td>
                          <td className="view-bill-data">
                            <img
                              // src="/assets/images/download_icon.png"
                              src={Images?.download_Icon}
                              alt="no-image"
                              className="--image-click"
                              onClick={() => {
                                navigate("/billingInvoice", {
                                  state: {
                                    item,
                                    userdata: resultData,
                                  },
                                });
                              }}
                            />
                          </td>
                          <td className="view-bill-data">{item?.roomNo}</td>
                          <td className="view-bill-data">{item?.billNo}</td>
                          <td className="view-bill-data">
                            {item?.mobileNumber}
                          </td>
                          <td className="view-bill-data">{item?.emailId}</td>
                          <td className="view-bill-data">{item?.date}</td>
                          <td className="view-bill-data">
                            {item?.rentForMonth}
                          </td>
                          <td className="view-bill-data">
                            {item?.currentAmount}
                          </td>
                          <td className="view-bill-data">{item?.others}</td>
                          <td className="view-bill-data">{item?.total}</td>
                          <td className="view-bill-data">
                            {item?.currentlypaidAmount}
                          </td>
                          <td className="view-bill-data">
                            {item?.remainingAmount}
                          </td>
                          <td className="view-bill-data">
                            <img
                              // src="/assets/images/edit-icon.png"
                              src={Images?.editIcon}
                              className="--image-click"
                              alt="no-image"
                              onClick={() =>
                                navigate("/editBill", { state: item })
                              }
                            />
                            <img
                              // src="/assets/images/delete-icon.png"
                              src={Images?.deleteIcon}
                              className="--image-click"
                              alt="no-image"
                              onClick={() => {
                                setIsOpen(true);
                                setPopupState({
                                  removeConfirm: true,
                                  deleteId: item?.id,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="14">No results found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-between pt-2 px-1">
            <button
              onClick={handlePrevPage}
              disabled={isPrevDisabled}
              className="arrow-btn"
            >
              &lt;
            </button>
            <button
              onClick={handleNextPage}
              disabled={isNextDisabled}
              className="arrow-btn"
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        setPopupState={setPopupState}
        message=" Are you sure you want to delete the bill detail?"
        selecthostel={selectedHostel}
        deleteHandler={handleDelete}
        isLoading={isLoading}
        dltmessage="Bill has been deleted successfully"
      />
    </>
  );
};

export default ViewBill;
