import axios from "axios";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Images from "../../assets/images/Images";
const Home = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();

  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    (state && state?.selectHostel) || branchNameArray[0] || ""
  );
  const getViewRegister = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newregister/getAllNewRegister/${selectedHostel}`
    );
  };
  const { data, isLoading, isError, refetch } = useQuery(
    ["getViewRegister", selectedHostel],
    getViewRegister,
    {
      enabled: !!selectedHostel,
    }
  );
  let details = data && data?.data;
  const ActiveCount =
    details?.filter((item) => item?.status === "Active").length || 0;
  const InactiveCount =
    details?.filter((item) => item?.status === "Inactive").length || 0;

  const totalregister = Number(ActiveCount) + Number(InactiveCount);

  const getViewBill = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/newbills/getAllNewBill/${selectedHostel}`
    );
    return response.data;
  };

  const { data: billdetails } = useQuery(
    ["getViewBill", selectedHostel],
    getViewBill,
    {
      enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
    }
  );
  const paidCount =
    (billdetails &&
      billdetails?.filter((item) => item?.feesStatus === "Paid").length) ||
    0;

  const pendingCount =
    (billdetails &&
      billdetails?.filter((item) => item?.feesStatus === "Pending").length) ||
    0;

  const billcount = Number(paidCount) + Number(pendingCount);

  const getViewRoom = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/rooms/getAllNewRoom/${selectedHostel}`
    );
  };

  const { data: roomdata } = useQuery(
    ["getViewRoom", selectedHostel],
    getViewRoom,
    {
      enabled: !!selectedHostel, // Ensure the query runs only if selectedHostel is not empty
    }
  );

  const availabledetails =
    (roomdata &&
      roomdata?.data &&
      roomdata &&
      roomdata?.data?.filter((item) => item?.status === "available").length) ||
    0;
  const unavailabledetails =
    (roomdata &&
      roomdata?.data &&
      roomdata &&
      roomdata?.data?.filter((item) => item?.status === "unavailable")
        .length) ||
    0;

  const roomcount = Number(availabledetails) + Number(unavailabledetails);
  return (
    <>
      <div className="row py-2 px-2 m-0 new-register-heading-block">
        <div className="col-lg-12  d-flex  justify-content-between align-items-center my-2 px-1">
          <div className="new-register-title-block">
            <img
              src={Images?.arrowBack}
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">HOME PAGE</h5>
          </div>
          <Controller
            name="selectHostel"
            control={control}
            defaultValue={selectedHostel}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange } }) => (
              <select
                value={selectedHostel}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSelectedHostel(newValue);
                  onChange(newValue);
                }}
                className={`selectDropDownHome ${
                  errors.selectHostel ? "error" : ""
                }`}
              >
                <option value="">Branch Name</option>
                {branchNameArray?.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
        </div>
      </div>
      <div className="row py-4 d-flex justify-content-center mt-4">
        <div className="col-10 homePageBlock">
          <div className="card home-page-card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <p className="home-content-para">Register Report</p>
                </div>
                <div className="col-auto">
                  <div
                    className="card-image-block text-primary"
                    onClick={() => {
                      navigate("/viewRegister", { state: selectedHostel });
                    }}
                  >
                    <img
                      src={Images?.register}
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      alt="no-image"
                    />
                  </div>
                </div>
              </div>
              <h3 className="mt-1 mb-3">{totalregister}</h3>

              <div className="card-text m-0 d-flex align-items-center gap-2">
                <span className="status-para">Active :</span>{" "}
                <span className="badge-primary-light">{ActiveCount}</span>
              </div>
              <div className="card-text m-0 d-flex align-items-center gap-2">
                <span className="status-para">Inactive :</span>{" "}
                <span className="badge-danger-light">{InactiveCount}</span>
              </div>
            </div>
          </div>
          <div className="card home-page-card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <p className="home-content-para">Bill Report</p>
                </div>
                <div className="col-auto">
                  <div
                    className="card-image-block text-primary"
                    onClick={() => {
                      navigate("/viewBill", { state: selectedHostel });
                    }}
                  >
                    <img
                      src={Images?.payment}
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      alt="no-image"
                    />
                  </div>
                </div>
              </div>

              <h3 className="mt-1 mb-3">{billcount}</h3>
              <div className="card-text m-0 d-flex align-items-center gap-2">
                <span className="status-para">Paid :</span>{" "}
                <span className="badge-primary-light">{paidCount}</span>
              </div>
              <div className="card-text m-0 d-flex align-items-center gap-2">
                <span className="status-para">Pending :</span>{" "}
                <span className="badge-danger-light">{pendingCount}</span>
              </div>
            </div>
          </div>
          <div className="card home-page-card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <p className="home-content-para">Room Report</p>
                </div>
                <div className="col-auto">
                  <div
                    className="card-image-block text-primary"
                    onClick={() => {
                      navigate("/viewRoom", { state: selectedHostel });
                    }}
                  >
                    <img
                      // src="/assets/images/room.png"
                      src={Images?.room}
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      alt="no-image"
                    />
                  </div>
                </div>
              </div>
              <h3 className="mt-1 mb-3">{roomcount}</h3>

              <div className="card-text m-0 d-flex align-items-center gap-2">
                <span className="status-para">Available :</span>{" "}
                <span className="badge-primary-light">{availabledetails}</span>
              </div>
              <div className="card-text m-0 d-flex align-items-center gap-2">
                <span className="status-para">Unavailable :</span>{" "}
                <span className="badge-danger-light">{unavailabledetails}</span>
              </div>
            </div>
          </div>
          <div className="card home-page-card">
            <div className="card-body">
              <p className="card-text home-content-para">OVER ALL REPORT</p>
              <div className="row mb-3 mt-2">
                <div className="col-auto">
                  <div className="card-image-block text-primary">
                    <img
                      // src="/assets/images/register.png"
                      src={Images?.register}
                      style={{ width: "24px", height: "24px" }}
                      alt="no-image"
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="card-image-block text-primary">
                    <img
                      // src="/assets/images/payment.png"
                      src={Images?.payment}
                      style={{ width: "24px", height: "24px" }}
                      alt="no-image"
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="card-image-block text-primary">
                    <img
                      // src="/assets/images/room.png"
                      src={Images?.room}
                      style={{ width: "24px", height: "24px" }}
                      alt="no-image"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-1 flex-wrap">
                <div className="card-text m-0 d-flex align-items-center gap-2">
                  <span className="status-para">Registers :</span>{" "}
                  <span className="badge-primary-light">{totalregister}</span>
                </div>
                <div className="card-text m-0 d-flex align-items-center gap-2">
                  <span className="status-para">Bills :</span>{" "}
                  <span className="badge-primary-light">{billcount}</span>
                </div>
                <div className="card-text m-0 d-flex align-items-center gap-2">
                  <span className="status-para">Rooms :</span>{" "}
                  <span className="badge-primary-light">{roomcount}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
