import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Images from "../../assets/images/Images";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
const Login = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({});
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(true);
  const [error, setError] = useState();

  const { mutate, isError, isLoading, status } = useMutation(
    (postdata) =>
      axios.post(`${process.env.REACT_APP_HOST}/registers/login`, postdata),
    {
      onSuccess: (obj) => {
        const branchNames =
          obj &&
          obj?.data &&
          obj?.data?.registers?.map((data, index) => {
            return data?.shopName;
          });
        if (Array.isArray(branchNames)) {
          localStorage.setItem("branchNames", JSON.stringify(branchNames));
        }

        const regidetails = obj && obj?.data && obj?.data?.registers;
        const users = JSON.stringify(regidetails);
        localStorage.setItem("registers", users);
        localStorage.setItem("login", obj?.data?.message || "");
        navigate("/home");
        window.location.reload();
      },
      onError: (error) => {
        setError(error);
        console.error("Mutation failed:", error);
      },
    }
  );
  const onsubmit = (data) => {
    // console.log("data", data);
    mutate(data);
  };

  return (
    <>
      <section className="loginMainSection">
        <div className="container">
          <div className="row p-0 m-0">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className="login-image-block">
                <div className="row p-0 m-0">
                  <div className="col-lg-7 login-slider-block">
                    <h6 className="image-head">LodgeStay</h6>
                    <div className="image-slider">
                      <Carousel
                        autoPlay={true}
                        infiniteLoop={true}
                        showThumbs={false}
                      >
                        <div>
                          <img src={Images?.image1} />
                        </div>
                        <div>
                          <img src={Images?.image2} />
                        </div>
                        <div>
                          <img src={Images?.image3} />
                        </div>
                        <div>
                          <img src={Images?.image4} />
                        </div>
                      </Carousel>
                    </div>
                  </div>
                  <div className="col-lg-5 login-form-block">
                    <form
                      onSubmit={handleSubmit(onsubmit)}
                      className="login-input-container"
                    >
                      <h6 className="login-head">Login</h6>
                      <div className="row">
                        <div className="col-12 d-flex flex-column form-group">
                          <label className="form-label" htmlFor="username">
                            Username
                          </label>
                          <Controller
                            control={control}
                            name="username"
                            render={({ field }) => (
                              <input
                                {...field}
                                type="emailid"
                                id="username"
                                placeholder="Username"
                                className="login-input-field"
                                required
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-flex flex-column form-group">
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                          <div className="login-password-block">
                            <Controller
                              control={control}
                              name="password"
                              render={({ field }) => (
                                <input
                                  {...field}
                                  placeholder="6 + Characters"
                                  id="password"
                                  // type="password"
                                  type={`${!isShow ? "text" : "password"}`}
                                  className="login-password-input-field"
                                />
                              )}
                            />
                            <div
                              style={{
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setIsShow(!isShow);
                              }}
                            >
                              {!isShow ? (
                                <img
                                  src={Images?.visibilityon}
                                  style={{ width: "100%", height: "100%" }}
                                  alt="no-image"
                                />
                              ) : (
                                <img
                                  src={Images?.visibilityoff}
                                  style={{ width: "100%", height: "100%" }}
                                  alt="no-image"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {isError && (
                        <p
                          style={{ color: "red", fontWeight: "500" }}
                          className="p-0 m-0"
                        >
                          {error &&
                            error?.response &&
                            error?.response?.data &&
                            error?.response?.data?.message}
                        </p>
                      )}
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <button className="login-button">
                            {isLoading ? (
                              <i class="fa fa-circle-o-notch fa-spin"></i>
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
