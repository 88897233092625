import arrowBackIcon from "../images/arrow-blue.png";
import uploadIcon from "../images/upload.png";
import camerIcon from "../images/camera.png";
import downloadIcon from "../images/download.png";
import registerIcon from "../images/register.png";
import roomIcon from "../images/room.png";
import billIcon from "../images/bill.png";
import paymentIcon from "../images/payment.png";
import logo from "../images/2k-logo.png";
import logout from "../images/logout.png";
import close from "../images/close.png";
import hamburgar from "../images/hamburger.png";
import image1 from "../images/image1.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.jpg";
import image4 from "../images/image4.jpg";
import editIcon from "../images/edit-icon.png";
import addIcon from "../images/addIcon.png";
import deleteIcon from "../images/delete-icon.png";
import visibilityon from "../images/visibility_on.png";
import visibilityoff from "../images/visibility_off.png";
import download_Icon from "../images/download_icon.png";
import billingIcon from "../images/billingsystem.jpg";
import closeIcon from "../images/close.png";

const Images = {
  arrowBack: arrowBackIcon,
  upload: uploadIcon,
  camera: camerIcon,
  download: downloadIcon,
  register: registerIcon,
  room: roomIcon,
  bill: billIcon,
  payment: paymentIcon,
  logo: logo,
  logout: logout,
  close: close,
  hamburgar: hamburgar,
  image1: image1,
  image2: image2,
  image3: image3,
  image4: image4,
  visibilityoff: visibilityoff,
  visibilityon: visibilityon,
  editIcon: editIcon,
  addIcon: addIcon,
  deleteIcon: deleteIcon,
  billIcon: billIcon,
  download_Icon: download_Icon,
  billingIcon: billingIcon,
  closeIcon: closeIcon,
};
export default Images;
