import React from "react";
import Sidebar from "../../components/Sidebar";
import EditRegisterComp from "../../components/editregister/EditRegisterComp";

const EditRegister = () => {
  return (
    <>
      <section className="mainSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10 p-0">
              <EditRegisterComp />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditRegister;
