import React, { useState } from "react";

import { useNavigate } from "react-router";
import axios from "axios";
import Images from "../assets/images/Images";

const PopupModal = ({
  isOpen,
  onClose,
  popupState,
  selecthostel,
  setPopupState,
  isLoading,
  message,
  errroMessage,
  updateMessage,
  onDone,
  deleteHandler,
  stateID,
  dltmessage,
}) => {
  const navigate = useNavigate();
  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        {popupState && popupState?.addData && (
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                }}
                onClick={onClose}
              >
                <img
                  src={Images?.closeIcon}
                  alt="no-image"
                  className="popUpBackGround"
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              {errroMessage ? (
                <>
                  <span className="popUpContentPara pb-2 pt-4">
                    {errroMessage}
                  </span>
                  <button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    OK
                  </button>
                </>
              ) : (
                <>
                  <span className="popUpContent p-2">Thank you!</span>
                  <span className="popUpContentPara pb-2">
                    {stateID ? updateMessage : message}
                  </span>
                  <button
                    // onClick={() => {
                    //   navigate("/viewRoom", { state: selecthostel });
                    //   onClose();
                    // }}
                    onClick={onDone}
                  >
                    OK
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        {popupState && popupState?.roomStatus && (
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                }}
                onClick={onClose}
              >
                <img
                  src={Images?.closeIcon}
                  alt="no-image"
                  className="popUpBackGround"
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="popUpContentPara pb-3 pt-4">
                Room Not Available Please Check Available Room !!
              </span>
              <button
                onClick={() => {
                  navigate("/viewRoom", { state: selecthostel });
                }}
              >
                OK
              </button>
            </div>
          </div>
        )}
        {popupState && popupState?.removeConfirm && (
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                }}
                onClick={onClose}
              >
                <img
                  src={Images?.closeIcon}
                  alt="no-image"
                  className="popUpBackGround"
                />
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="popupDeleteUser my-2 px-1">{message}</p>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <button
                  onClick={async () => {
                    if (popupState?.deleteId) {
                      await deleteHandler(popupState?.deleteId); // Trigger the delete handler passed from parent
                      setPopupState({
                        removeConfirm: false, // Hide the confirmation modal
                        removeSucess: true, // Indicate success
                      });
                    }
                  }}
                >
                  {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "YES"}
                </button>
                <button onClick={onClose}>NO</button>
              </div>
            </div>
          </div>
        )}
        {popupState && popupState?.removeSucess && (
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                }}
                onClick={onClose}
              >
                <img
                  src={Images?.closeIcon}
                  alt="no-image"
                  className="popUpBackGround"
                />
              </div>
            </div>
            <div className="pt-3 d-flex flex-column justify-content-center align-items-center">
              <p className="popupDeleteUser m-0 p-0">{dltmessage}</p>
              <button onClick={onClose}>OK</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PopupModal;
