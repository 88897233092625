import React from "react";
import Sidebar from "../../components/Sidebar";
import NewRegister from "../../components/newregister/NewRegister";

const NewRegisterPage = () => {
  return (
    <>
      <section className="mainSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10 p-0">
              <NewRegister />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewRegisterPage;
