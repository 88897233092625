import React from "react";
import Sidebar from "../../components/Sidebar";
import EditBillComp from "../../components/editbill/EditBillComp";

const EditBill = () => {
  return (
    <>
      <section className="mainSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10 p-0">
              {/* <NewRegister /> */}
              <EditBillComp />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditBill;
