import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import moment from "moment";
import BillingAddInputField from "../BillingAddInputField";
import Images from "../../assets/images/Images";
import PopupModal from "../PopupModal";

const EditBillComp = () => {
  const { state } = useLocation();
  // console.log("state", state);
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  // Set the initial state based on the branchNameArray
  const [selectedHostel, setSelectedHostel] = useState(
    state?.selectHostel || branchNameArray[0] || ""
  );

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      date: moment().toDate(),
      others: 0,
      currentAmount: 0,
      currentlypaidAmount: 0,
      paymentType: "Full Payment",
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    addData: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };
  const handleNavigate = () => {
    navigate("/viewBill", { state: selectedHostel });
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);

  const [details, setDetails] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const {
    currentAmount,
    rentForMonth,
    others,
    powerUsedFrom,
    powerUsedTo,
    paymentType,
    amountPerUnit,
    currentlypaidAmount,
    total,
  } = watch();

  useEffect(() => {
    // setValue("selectHostel", state?.role);
    if (state?.role) {
      setSelectedHostel(state?.role);
      setValue("selectHostel", state?.role); // Update the react-hook-form field
    }
    setValue("date", state?.date);
    setValue("billNo", state?.billNo);
    setValue("name", state?.name);
    setValue("mobileNumber", state?.mobileNumber);
    setValue("email", state?.emailId);
    setValue("remainingAmount", state?.remainingAmount);
    setValue("currentlypaidAmount", state?.currentlypaidAmount);
    setValue("roomNo", state?.roomNo);
    setValue("rentForMonth", state?.rentForMonth);
    setValue("others", state?.others);
    setValue("total", state?.total);
    setValue("currentAmount", state?.currentAmount);
    setValue("paymentType", state?.payementType);
    setValue("powerUsedFrom", state?.powerUsedFrom);
    setValue("powerUsedTo", state?.powerUsedTo);
    setValue("amountPerUnit", state?.selecthostel);
  }, [state, setValue, state?.role, selectedHostel]);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postdata) =>
      axios.put(
        `${
          process.env.REACT_APP_HOST
        }/newbills/updateNewBill/${selectedHostel}/${state && state.id}`,
        postdata
      ),
    {
      onSuccess: (val) => {
        // setUpdatePopup(1);
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  const onSubmit = (data) => {
    let formData;
    if (state && state.id != null && selectedHostel) {
      formData = {
        id: state.id,
        name: data?.name,
        date: moment(data?.date).format("YYYY-MM-DD"),
        mobileNumber: data?.mobileNumber,
        emailId: data?.email,
        roomNo: data?.roomNo,
        currentAmount: data?.currentAmount,
        others: data?.others,
        rentForMonth: data?.rentForMonth,
        role: data?.selectHostel,
        total: data?.total,
        payementType: data?.paymentType,
        billNo: state?.billNo,
        powerUsedFrom: data?.powerUsedFrom,
        powerUsedTo: data?.powerUsedTo,
        selecthostel: data?.amountPerUnit,
        currentlypaidAmount:
          Number(data?.currentlypaidAmount) + Number(data?.remainingAmount),
        remainingAmount: 0,
        // Number(data?.currentlyPaidAmount) + Number(data?.remainingAmount),

        // Number(data?.total) -
        // Number(data?.currentlyPaidAmount) -
        // Number(data?.remainingAmount),
      };
      mutate(formData);
    }
  };

  return (
    <>
      <div className="row py-2 px-2 m-0 new-register-heading-block">
        <div className="col-12 col-sm-4 col-md-4 my-1 px-1">
          <div className="new-register-title-block">
            <img
              // src="/assets/images/arrow-blue.png"
              src={Images?.arrowBack}
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">Edit BILL</h5>
          </div>
        </div>
        <div className="col-12 col-sm-8 d-flex justify-content-between justify-content-md-end align-items-center gap-2 my-1 px-1">
          <Controller
            name="selectHostel"
            control={control}
            defaultValue={selectedHostel}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange } }) => (
              <select
                value={selectedHostel}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSelectedHostel(newValue); // Update local state
                  onChange(newValue); // Update react-hook-form field
                }}
                className={`selectDropDown ${
                  errors.selectHostel ? "error" : ""
                }`}
              >
                <option value="">Branch Name</option>
                {branchNameArray.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
        </div>
      </div>

      <div className="register-form-main-block">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="rentalFormBlock my-3"
        >
          <div className=" py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Date</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="date"
                render={({ field }) => (
                  <DatePicker
                    className="bookingDateEditInput"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    placeholderText="DD/MM/YY"
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Bill No
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="billNo"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Bill Number"
                    type="number"
                    bookingEditInput="bookingEditInput"
                    disabled="disabled"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Name <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="name"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Customer Name"
                    type="text"
                    bookingEditInput="bookingEditInput"
                    disabled="disabled"
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Mobile Number
                <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="mobileNumber"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Mobile Number"
                    type="number"
                    bookingEditInput="bookingEditInput"
                    disabled="disabled"
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Email Id
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="email"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Email Id"
                    type="emailid"
                    bookingEditInput="bookingEditInput"
                    disabled="disabled"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Room No <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="roomNo"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Room No"
                    bookingEditInput="bookingEditInput"
                    type="text"
                    disabled="disabled"
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Power Used Units <span style={{ color: "red" }}>*</span>
              </label>
            </div>

            <div className="billingInputFieldBlock d-flex">
              <div className="fromUnitsBlock">
                <Controller
                  control={control}
                  name="powerUsedFrom"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="from Units"
                      bookingEditInput="bookingEditInput"
                      type="number"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className="toUnitsBlock">
                <Controller
                  control={control}
                  name="powerUsedTo"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="to Units"
                      bookingEditInput="bookingEditInput"
                      type="number"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock d-flex justify-content-end">
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="amountPerUnit"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Amount Per Unit"
                    bookingEditInput="bookingEditInput"
                    type="text"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                EB Amount
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="currentAmount"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="currentAmount"
                    bookingEditInput="bookingEditInput"
                    type="number"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Rent For Month <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="rentForMonth"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Rent For Month"
                    bookingEditInput="bookingEditInput"
                    type="number"
                    disabled="disabled"
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Others</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="others"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Old Balance"
                    bookingEditInput="bookingEditInput"
                    type="number"
                    disabled="disabled"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Total</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="total"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Total"
                    bookingEditInput="bookingEditInput"
                    type="number"
                    disabled="disabled"
                  />
                )}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Payment Method</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                name="paymentType"
                control={control}
                defaultValue="Full Payment"
                // rules={{ required: true }}
                render={({ field }) => (
                  <select {...field} className="bookingEditInput">
                    <option value="">PAYMENT METHOD</option>
                    <option value="Full Payment">Full Payment</option>
                    <option value="Part Payment">Part Payment</option>
                  </select>
                )}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">
                Currently Paid Amount
              </label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="currentlypaidAmount"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder={
                      paymentType === "Full Payment"
                        ? "Total Amount"
                        : "Enter Amount"
                    }
                    bookingEditInput="bookingEditInput"
                    type="number"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-2 px-4 billingMainInputBlock">
            <div className="billingInputLabelBlock">
              <label className="BillingAddInputLabel">Pending Amount</label>
            </div>
            <div className="billingInputFieldBlock">
              <Controller
                control={control}
                name="remainingAmount"
                render={(field) => (
                  <BillingAddInputField
                    {...field}
                    placeholder="Pending"
                    bookingEditInput="bookingEditInput"
                    type="number"
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="py-4 px-4  billingSubmitButton">
            <button type="submit" className="formSubmit">
              {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "Submit"}
            </button>
          </div>
        </form>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        updateMessage="Bill Has Been Updated Successfully.."
        // errroMessage={errroMessage}
        selecthostel={selectedHostel}
        stateID={state?.id}
        onDone={handleNavigate}
      />
    </>
  );
};

export default EditBillComp;
