import React from "react";

const ImageModal = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
        <img
          src={
            imageUrl && imageUrl?.startsWith("data:image/jpeg;base64,")
              ? imageUrl
              : `data:image/jpeg;base64,${imageUrl}`
          }
          alt="no-image"
          className="modal-image"
        />
        <button className="btn btn-danger close-button" onClick={onClose}>
          X
        </button>
      </div>
    </div>
  );
};

export default ImageModal;
