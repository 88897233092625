import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/images/Images";
import { useQuery } from "react-query";
import axios from "axios";

const ViewBedComp = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const getViewRegister = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/newregister/getAllNewRegister/${state?.selectHostel}`
    );
  };
  const { data, isLoading, isError } = useQuery(
    ["getViewRegister", state?.selectHostel],
    getViewRegister
  );
  let details = data && data?.data;
  let filteredDetails;
  let i = 0;
  if (Array.isArray(details) && state?.roomNo != null) {
    // Filter the details array to include only the objects with the matching roomNo
    filteredDetails = details?.filter((item) => item?.roomNo === state?.roomNo);
    // console.log("MAPPED DETAILS", filteredDetails);
  }

  return (
    <>
      <div className="row py-2 px-2 m-0 new-register-heading-block">
        <div className="col-12 col-md-6 d-flex  justify-content-between align-items-center my-1 px-1">
          <div className="new-register-title-block">
            <img
              src={Images?.arrowBack}
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">VIEW BED'S</h5>
          </div>
        </div>
      </div>
      <div className="view-room-block">
        <div className="view-bed-main-block">
          <h6 className="view-beds-title">ROOM NUMBER : {state?.roomNo}</h6>
          <div className="beds-card-block">
            {filteredDetails?.length == 0 ? (
              <>
                <div
                  class="card text-center card-block"
                  onClick={() => {
                    navigate("/newRegister", { state: state });
                  }}
                >
                  <div class="card-body view-beds-add-block">
                    <img src={Images?.addIcon} alt="no-image" />

                    <h6 className="card-content-para">ADD USER</h6>
                  </div>
                </div>
              </>
            ) : (
              <>
                {filteredDetails &&
                  filteredDetails?.map((e, i) => {
                    return (
                      <>
                        <div
                          class="card text-center  card-block"
                          key={i}
                          //   onClick={() => {
                          //     handlePopup(e);
                          //   }}
                        >
                          <div class="card-body d-flex justify-content-center align-items-center flex-column">
                            <h6 className="d-flex gap-3 p-0 m-0">
                              <span class="card-title card-content-para">
                                {e?.roomNo} B{e?.bedNo} - {e?.name} -{" "}
                                {e?.status}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </>
                    );
                  })}
                {(state && state?.status == "available") ||
                (state && state?.status == "Available") ? (
                  <>
                    <div
                      class="card text-center card-block"
                      onClick={() => {
                        navigate("/newRegister", {
                          state: state,
                        });
                      }}
                    >
                      <div class="card-body d-flex align-items-center justify-content-center">
                        <img src="/assets/images/addIcon.png" alt="no-image" />
                        <h6 className="card-content-para">ADD USER</h6>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBedComp;
