import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NewRoomPage from "../screens/newroom/NewRoomPage";
import ViewRoomPage from "../screens/viewroom/ViewRoomPage";
import { QueryClient, QueryClientProvider } from "react-query";
import NewRegisterPage from "../screens/newregister/NewRegisterPage";
import ViewRegisterPage from "../screens/viewregister/ViewRegisterPage";
import BillPage from "../screens/newbill/BillPage";
import ViewBillPage from "../screens/viewbill/ViewBillPage";
import HomePage from "../screens/home/HomePage";
import Login from "../screens/loginscreen/Login";
import EditRegister from "../screens/editregister/EditRegister";
import ViewBed from "../screens/viewbeds/ViewBed";
import EditBill from "../screens/editbill/EditBill";
import Invoice from "../screens/invoice/Invoice";

const Navigation = () => {
  const queryClient = new QueryClient();
  return (
    <>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/newRoom" element={<NewRoomPage />} />
            <Route path="/viewRoom" element={<ViewRoomPage />} />
            <Route path="/newRegister" element={<NewRegisterPage />} />
            <Route path="/viewRegister" element={<ViewRegisterPage />} />
            <Route path="/newBill" element={<BillPage />} />
            <Route path="/viewBill" element={<ViewBillPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/viewBed" element={<ViewBed />} />
            <Route path="/editRegister" element={<EditRegister />} />
            <Route path="/editBill" element={<EditBill />} />
            <Route path="/billingInvoice" element={<Invoice/>}/>
          </Routes>
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
};

export default Navigation;
