import React, { useState, useEffect } from "react";
import BillingAddInputField from "../BillingAddInputField";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/images/Images";
import { useMutation } from "react-query";
import axios from "axios";
import PopupModal from "../PopupModal";

const NewRoom = () => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    addData: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };
  
  const handleNavigate = () => {
    navigate("/viewRoom", { state: selectedHostel });
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  const [errroMessage, setErrorMessage] = useState("");
  const storedBranchNames = localStorage.getItem("branchNames");
  const branchNameArray = JSON.parse(storedBranchNames) || [];

  const getSelectedHostelFromState = (state) => {
    if (!state) return branchNameArray[0] || ""; // Default to the first branch or empty
    if (typeof state === "string") return state; // Handle string state
    if (typeof state === "object" && state?.selectHostel)
      return state?.selectHostel; // Handle object state
    return branchNameArray[0] || ""; // Fallback
  };

  const [selectedHostel, setSelectedHostel] = useState("");

  useEffect(() => {
    const initialHostel = getSelectedHostelFromState(state);
    setSelectedHostel(initialHostel);
    setValue("selectHostel", initialHostel);
  }, [state, setValue]);

  // Handle the selection change
  const handleHostelChange = (e) => {
    const newValue = e.target.value;
    setSelectedHostel(newValue);
    setValue("selectHostel", newValue);
  };

  useEffect(() => {
    if (state && typeof state === "object") {
      setValue("roomNumber", state?.roomNo || "");
      setValue("NoOfBeds", state?.noOfBeds || "");
      setValue("roomType", state?.roomType || "");
      setValue("ebNumber", state?.ebNumber || "");
    }
  }, [state, setValue]);

  const { mutate, isLoading } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/rooms/${selectedHostel}`,
        postData
      ),
    {
      onSuccess: () => {
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
      },
      onError: (error) => {
        if (error?.response) {
          const { data } = error?.response;
          setErrorMessage(data);
          setIsOpen(true);
          setPopupState({
            addData: true,
          });
        } else if (error?.response?.status === 500) {
          alert(
            "Oops! Something went wrong on our end. Please try again later."
          );
        }
        return Promise.reject();
      },
    }
  );

  const updateValue = useMutation(
    (postdata) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/rooms/updateNewRoom/${selectedHostel}/${
          state && state?.id
        }`,
        postdata
      ),
    {
      onSuccess: (val) => {
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
        // setUpdatePopup(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const onSubmit = (data) => {
    let formData = {
      roomNo: data?.roomNumber,
      noOfBeds: data?.NoOfBeds,
      roomType: data?.roomType,
      ebNumber: data?.ebNumber,
      branchName: data?.branchName,
      selectHostel: data?.selectHostel,
    };
    if (state && state?.id) {
      updateValue.mutate(formData);
    } else {
      mutate(formData);
    }
  };
  return (
    <>
      <div className="row py-2 px-2 m-0 new-register-heading-block">
        <div className="col-12 col-sm-6 col-md-6 my-2 px-1">
          <div className="new-register-title-block">
            <img
              src={Images?.arrowBack}
              alt="no-image"
              className="arrow-img-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="register-title pt-2">NEW ROOM</h5>
          </div>
        </div>
        <div className="col-12 col-sm-6 d-flex justify-content-between justify-content-md-end align-items-center gap-2 my-2">
          <Controller
            name="selectHostel"
            control={control}
            defaultValue={selectedHostel}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <select
                {...field}
                value={selectedHostel}
                onChange={handleHostelChange}
                className={`selectDropDownNewRoom ${
                  errors.selectHostel ? "error" : ""
                }`}
              >
                <option value="">Branch Name</option>
                {branchNameArray?.map((branchName) => (
                  <option key={branchName} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            )}
          />
          <button
            className="view-register-btn"
            onClick={() => {
              navigate("/newRoom");
            }}
          >
            VIEW ROOM
          </button>
        </div>
      </div>

      <div className="new-room-form-main-block">
        <div className="new-room-form-block  my-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row m-0 p-0 mt-3 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">BRANCH NAME </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <Controller
                  name="branchName"
                  control={control}
                  render={({ field }) => (
                    <select
                      {...field}
                      className={`newRoomInputField ${
                        errors.branchName ? "error" : ""
                      }`}
                    >
                      <option value="">Branch Name</option>
                      <option value="Lodge Stays">Lodge Stays</option>
                    </select>
                  )}
                />
              </div>
            </div>
            <div className="row my-1 mx-0 p-0  align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  ROOM NUMBER{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <Controller
                  control={control}
                  name="roomNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Room Number"
                      type="text"
                      popupInputField="popup-input-field"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row my-1 mx-0 p-0 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  NO OF BEDS{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <Controller
                  control={control}
                  name="NoOfBeds"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Sharing"
                      type="number"
                      popupInputField="popup-input-field"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row mx-0 my-1 p-0 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">
                  ROOM TYPE{" "}
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <Controller
                  name="roomType"
                  control={control}
                  defaultValue="With AC"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className={`newRoomInputField ${
                        errors.branchName ? "error" : ""
                      }`}
                    >
                      <option value="">Room Type</option>
                      <option value="With AC">With AC</option>
                      <option value="Without AC">Without AC</option>
                    </select>
                  )}
                />
              </div>
            </div>
            <div className="row mx-0 my-1 p-0  align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <label className="newRoomInputLabel">EB NUMBER </label>
              </div>
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <Controller
                  control={control}
                  name="ebNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="EB Number"
                      type="text"
                      popupInputField="popup-input-field"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row m-0 p-0 mt-3 mb-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-6 col-md-3 my-1"></div>
              <div className="col-12 col-sm-6 col-md-3 my-1">
                <button type="submit" className="new-room-submit-btn">
                  {isLoading ? (
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        message="Room Added Successfully.."
        updateMessage="Room Has Been Updated Successfully.."
        errroMessage={errroMessage}
        selecthostel={selectedHostel}
        stateID={state?.id}
        onDone={handleNavigate}
      />
    </>
  );
};

export default NewRoom;
